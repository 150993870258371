import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import {
  deleteUserCookies,
  getLocalRefreshToken,
  isUserRefreshInProgress,
  setUserCookiesAreRefreshing,
  updateLocalAccessToken,
} from 'modules/user/userCookies';

/*export const onRequestRejected = async (error: AxiosError) => {
    if (error.config) {
        console.error('Request Configuration:', error.config);
    }

    console.error('Request Error:', error.message || 'Unknown error occurred');

    if (error.code === 'ECONNABORTED') {
        console.error('Request timed out:', error.config?.url);
    } else if (error.message.includes('Network Error')) {
        console.error('Network error detected. Ensure the client is online.');
    }

    // Forward the error downstream
    return Promise.reject(error);
};

export const onRequestFulfilled = async (config: AxiosRequestConfig) => {
    const refreshToken = getLocalRefreshToken()
        ? jwtDecode<{ exp: number }>(getLocalRefreshToken() || '')
        : undefined;
    const localAccessToken = getLocalAccessToken()
        ? jwtDecode<{ exp: number }>(getLocalAccessToken() || '')
        : undefined;

    const currentTime = Date.now() / 1000;

    const handleStorageChange = (event: StorageEvent) => {
        if (event.key === 'notifyAccessTokenRefresh') {
            const newToken = getLocalAccessToken();
            axios.defaults.headers.Authorization = `Bearer ${newToken}`;
            config.headers.Authorization = `Bearer ${newToken}`;
        }
    };

    window.addEventListener('storage', handleStorageChange);

    if (
        refreshToken &&
        localAccessToken &&
        localAccessToken.exp < currentTime + 1 &&
        refreshToken.exp > currentTime
    ) {
        if (localStorage.getItem('isUserRefreshLocalTokenInProgress') === 'true') {
            const timeout = 5000; // 5-second timeout
            const startTime = Date.now();

            while (
                localStorage.getItem('isUserRefreshLocalTokenInProgress') === 'true'
                ) {
                if (Date.now() - startTime > timeout) {
                    console.error('Token refresh timeout exceeded.');
                    window.removeEventListener('storage', handleStorageChange);
                    return config;
                }

                await sleep(250); // Sleep for 250ms before rechecking
            }

            await sleep(100); // Sleep for 100ms before updating header
            const newToken = getLocalAccessToken();
            config.headers.Authorization = `Bearer ${newToken}`;
            window.removeEventListener('storage', handleStorageChange);
            return config;
        }

        localStorage.setItem('isUserRefreshLocalTokenInProgress', 'true');

        try {
            console.log('Access token is about to expire. Attempting to refresh...');

            const refreshResponse = await axios
                .create({
                    headers: {
                        Authorization: `Bearer ${getLocalRefreshToken()}`,
                    },
                })
                .post(REFRESH_TOKEN_URL);

            const newToken = refreshResponse.data.access_token;
            updateLocalAccessToken(newToken);
            setUserCookiesAreRefreshing();
            axios.defaults.headers.Authorization = `Bearer ${newToken}`;
            config.headers.Authorization = `Bearer ${newToken}`;
            localStorage.setItem('notifyAccessTokenRefresh', Date.now().toString());
            window.removeEventListener('storage', handleStorageChange);
            localStorage.removeItem('isUserRefreshLocalTokenInProgress');
        } catch (error) {
            console.error('Error while refreshing the access token:', error);
            window.removeEventListener('storage', handleStorageChange);
            localStorage.removeItem('isUserRefreshLocalTokenInProgress');
        }
    } else if (refreshToken && refreshToken.exp < currentTime) {
        console.error('Refresh token is expired');
        deleteUserCookies();
        setUserCookiesAreRefreshing();
        window.location.href = window.location.origin + '/login';
        window.removeEventListener('storage', handleStorageChange);
        return Promise.reject(new Error('Refresh token expired'));
    }

    return config;
};*/

export const onFulfilled = (response: AxiosResponse) => {
  return response;
};

const REFRESH_TOKEN_URL = '/api/refresh-token';

const SENTRY_FILTERED_MESSAGES = [
  'Min expected allocation to run the strategy',
  'The minimum base order size for this strategy is',
  'Number of positions must be greater than',
  'Invalid reCAPTCHA',
];

// 1. rejection on normal request
// 2. if it is 401 on normal reuest we should do refresh token call ONCE (now is more) - maybe we shouldnt do refresh token call once is expired
// 3a. success - save the access token ->  you come back to redo the orginal request
// 3b. failure - delete user and push to login
// TODO: FIX token refresh

// If there is a 401 in one api we are logged out immediately so the logic below does not work, when working on this ask to the backend team to reduce the
// backend team to reduce the jwt access token expiration time to 5 sec to try out.

export const onRejected = async (error: AxiosError) => {
  const originalRequest = error.config;
  if (originalRequest.url?.includes(REFRESH_TOKEN_URL)) {
    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    const refreshToken: string | undefined = getLocalRefreshToken();
    if (isUserRefreshInProgress()) {
      console.log('User token is already refreshing');
      return axios.request(originalRequest);
    }

    if (refreshToken) {
      try {
        const refreshResponse = await axios
          .create({
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          })
          .post(REFRESH_TOKEN_URL);
        const newToken = refreshResponse.data.access_token;
        updateLocalAccessToken(newToken);
        setUserCookiesAreRefreshing();
        axios.defaults.headers.Authorization = 'Bearer ' + newToken;
        originalRequest.headers.Authorization = 'Bearer ' + newToken;
      } catch {
        deleteUserCookies();
        setUserCookiesAreRefreshing();
        window.location.href = window.location.origin + '/login';
        return Promise.reject(error);
      }
      return axios.request(originalRequest);
    }
  }

  const extractErrorMessage = () =>
    `${error.response?.status || ''}: ${error.response?.statusText || ''}`;

  const message =
    (error.response && error.response.data
      ? error.response.data.message
      : error.message) || extractErrorMessage();

  const shouldSendToSentry = !SENTRY_FILTERED_MESSAGES.some((filterMessage) =>
    message.startsWith(filterMessage)
  );

  // Token is not valid anymore so we need to logout user
  if (
    error.response?.status === 422 &&
    error.response.data?.msg === 'Signature verification failed'
  ) {
    deleteUserCookies();
    window.location.href = window.location.origin + '/login';
    return Promise.reject(error);
  }

  if (
    shouldSendToSentry &&
    error.response?.status !== 500 &&
    error.response?.status !== 422
  ) {
    Sentry.captureMessage(message);
  }
  const isMeaningfulErrorsApi = Array.isArray(error.response?.data?.errors);
  if (isMeaningfulErrorsApi) {
    (error as any).errors = error.response?.data?.errors;
    return Promise.reject(error);
  }

  const isDetailedApiError = Array.isArray(error.response?.data);
  if (isDetailedApiError) {
    return Promise.reject(error.response?.data);
  }

  return Promise.reject(message);
};
